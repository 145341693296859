import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
     {
    path: "/",
    // path: "*",
    name: "home",
    redirect: "/",
    component: resolve => require(["@/views/Home"], resolve),
    children: [
        {
        path: "/",
        name: "/index",
        component: resolve => require(["@/views/index"], resolve),
        meta: {
            title: "首页",
            keepAlive: true,
            content: {
				keywords: '果尔佳,建筑防水,防火,防腐,保温隔热,装饰装修,地坪,步道,房建,幕墙,钢构,机电,环保,消防,模板脚手架,加固特种工程,人才培养与劳务输出,产品供应链',
				description: '果尔佳成立于2006年，公司始终坚守“还建筑之美”的使命，为您提供优质的超值服务而努力奋斗。经19年的发展，成为国内领先的建筑产业服务商。业务包括产业工人的教育、供应链交互、优质工程的交付。'
			}
        }
      },
      {
        path: "/scopeServices",
        name: "/scopeServices",
        component: resolve => require(["@/views/scopeServices"], resolve),
        meta: {
            title: "服务范围" ,
            keepAlive: true,
            content: {
				keywords: '果尔佳,建筑防水,防火,防腐,保温隔热,装饰装修,地坪,步道,房建,幕墙,钢构,机电,环保,消防,模板脚手架,加固特种工程,人才培养与劳务输出,产品供应链',
				description: '防水,防腐,保温隔热,步道,地坪,防火,内外墙,装饰装修'
			}
        }
      },
      {
        path: "/solution",
        name: "solution",
        component: resolve => require(["@/views/solution"], resolve),
        meta: {
            title: "解决方案" ,
            keepAlive: true,
            content: {
				keywords: '果尔佳,建筑防水,防火,防腐,保温隔热,装饰装修,地坪,步道,房建,幕墙,钢构,机电,环保,消防,模板脚手架,加固特种工程,人才培养与劳务输出,产品供应链',
				description: '民用建筑,商业建筑,运动场所'
			}
        }
      },
      {
        path: "/solutionDetails",
        name: "solutionDetails",
        component: resolve => require(["@/views/solutionDetails"], resolve),
        meta: {
            title: "解决方案详情" ,
            content: {
				keywords: '果尔佳,建筑防水,防火,防腐,保温隔热,装饰装修,地坪,步道,房建,幕墙,钢构,机电,环保,消防,模板脚手架,加固特种工程,人才培养与劳务输出,产品供应链',
				description: '民用建筑,商业建筑,运动场所'
			}
        }
      },
      {
        path: "/projectCase",
        name: "projectCase",
        component: resolve => require(["@/views/projectCase"], resolve),
        meta: {
            title: "工程案例" ,
            keepAlive: true,
            content: {
				keywords: '果尔佳,建筑防水,防火,防腐,保温隔热,装饰装修,地坪,步道,房建,幕墙,钢构,机电,环保,消防,模板脚手架,加固特种工程,人才培养与劳务输出,产品供应链',
				description: '工厂物流仓储,市政交通,问题教育,商业集团,商业地产住宅'
			}
        }
      },
      {
        path: "/projectCaseDetails",
        name: "projectCaseDetails",
        component: resolve => require(["@/views/projectCaseDetails"], resolve),
        meta: {
            title: "工程案例详情" ,
            content: {
				keywords: '果尔佳,建筑防水,防火,防腐,保温隔热,装饰装修,地坪,步道,房建,幕墙,钢构,机电,环保,消防,模板脚手架,加固特种工程,人才培养与劳务输出,产品供应链',
				description: '工厂物流仓储,市政交通,问题教育,商业集团,商业地产住宅'
			}
        }
      },
      {
        path: "/productCenter",
        name: "/productCenter",
        component: resolve => require(["@/views/productCenter"], resolve),
        meta: {
            title: "产品中心" ,
            keepAlive: true,
            content: {
				keywords: '果尔佳,建筑防水,防火,防腐,保温隔热,装饰装修,地坪,步道,房建,幕墙,钢构,机电,环保,消防,模板脚手架,加固特种工程,人才培养与劳务输出,产品供应链',
				description: '防火材料,防火材料,防腐材料,墙面装饰,地坪材料,保温隔热,加固与维修,密封与粘接,辅材,设备与工具'
			}
        }
      },
      {
        path: "/goodsDetails",
        name: "/goodsDetails",
        component: resolve => require(["@/views/goodsDetails"], resolve),
        meta: {
            title: "产品详情" ,
            
            content: {
				keywords: '果尔佳,建筑防水,防火,防腐,保温隔热,装饰装修,地坪,步道,房建,幕墙,钢构,机电,环保,消防,模板脚手架,加固特种工程,人才培养与劳务输出,产品供应链',
				description: '防火材料,防火材料,防腐材料,墙面装饰,地坪材料,保温隔热,加固与维修,密封与粘接,辅材,设备与工具'
			}
        }
      },
      {
        path: "/news/index",
        name: "news",
        component: resolve => require(["@/views/news/index"], resolve),
        meta: {
            title: "新闻资讯" ,
            keepAlive: true,
            content: {
				keywords: '果尔佳,建筑防水,防火,防腐,保温隔热,装饰装修,地坪,步道,房建,幕墙,钢构,机电,环保,消防,模板脚手架,加固特种工程,人才培养与劳务输出,产品供应链',
				description: '公司新闻,行业资讯,视频中心'
			}
        }
      },
      {
        path: "/news/newsDetails",
        name: "newsDetails",
        component: resolve => require(["@/views/news/newsDetails"], resolve),
        meta: {
            title: "新闻详情",
            
            content: {
				keywords: '果尔佳,建筑防水,防火,防腐,保温隔热,装饰装修,地坪,步道,房建,幕墙,钢构,机电,环保,消防,模板脚手架,加固特种工程,人才培养与劳务输出,产品供应链',
				description: '公司新闻,行业资讯,视频中心'
			}
        }
      }, 
      {
        path: "/aboutUs",
        name: "/aboutUs",
        component: resolve => require(["@/views/aboutUs"], resolve),
        meta: {
            title: "关于我们" ,
            keepAlive: true,
            content: {
				keywords: '果尔佳,建筑防水,防火,防腐,保温隔热,装饰装修,地坪,步道,房建,幕墙,钢构,机电,环保,消防,模板脚手架,加固特种工程,人才培养与劳务输出,产品供应链',
				description: '公司简介,发展历程,荣誉资质,企业文化'
			}
        }
      },
      {
        path: "/contactUs",
        name: "/contactUs",
        component: resolve => require(["@/views/contactUs"], resolve),
        meta: {
            title: "联系我们" ,
            keepAlive: true,
            content: {
				keywords: '果尔佳,建筑防水,防火,防腐,保温隔热,装饰装修,地坪,步道,房建,幕墙,钢构,机电,环保,消防,模板脚手架,加固特种工程,人才培养与劳务输出,产品供应链',
				description: '果尔佳成立于2006年，公司始终坚守“还建筑之美”的使命，为您提供优质的超值服务而努力奋斗。经19年的发展，成为国内领先的建筑产业服务商。业务包括产业工人的教育、供应链交互、优质工程的交付。'
			}
        }
        },
        {
            path: "/waterproof",
            name: "/waterproof",
            component: resolve => require(["@/views/waterproof"], resolve),
            meta: {
                title: "专业防水",
                keepAlive: true,
                content: {
                    keywords: '果尔佳,建筑防水,防火,防腐,保温隔热,装饰装修,地坪,步道,房建,幕墙,钢构,机电,环保,消防,模板脚手架,加固特种工程,人才培养与劳务输出,产品供应链',
                    description: '果尔佳成立于2006年，公司始终坚守“还建筑之美”的使命，为您提供优质的超值服务而努力奋斗。经19年的发展，成为国内领先的建筑产业服务商。业务包括产业工人的教育、供应链交互、优质工程的交付。'
                }
            }
        },
        {
            path: "/renovate",
            name: "/renovate",
            component: resolve => require(["@/views/renovate"], resolve),
            meta: {
                title: "专业防水",
                keepAlive: true,
                content: {
                    keywords: '果尔佳,建筑防水,防火,防腐,保温隔热,装饰装修,地坪,步道,房建,幕墙,钢构,机电,环保,消防,模板脚手架,加固特种工程,人才培养与劳务输出,产品供应链',
                    description: '果尔佳成立于2006年，公司始终坚守“还建筑之美”的使命，为您提供优质的超值服务而努力奋斗。经19年的发展，成为国内领先的建筑产业服务商。业务包括产业工人的教育、供应链交互、优质工程的交付。'
                }
            }
        },
      
    ]}, 
]

const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
  routes,
})

export default router

<template>
    <div id="app">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    created() {
        this.$nextTick(() => {
            // 禁用右键
            document.oncontextmenu = new Function('event.returnValue=false')
            // 禁用选择
            document.onselectstart = new Function('event.returnValue=false')
        })
        document.addEventListener('keydown', function (e) {
            if (e.key == 'F12') {
                e.preventDefault() // 如果按下键F12,阻止事件
            }
        })
    },
    mounted() {
    },
    // 监听路由变化
    watch: {
        $route: 'urlName',
    },

    methods: {

        urlName() {
            let url = window.location.href
            if (url.indexOf('/') >= 0) {
                if (url.indexOf('/news/index') >= 0) {
                    document.title = this.$route.query.name + '—果尔佳'
                } else {
                    document.title = '首页—果尔佳'
                }
            } else {
                document.title = this.$route.query.name + '—果尔佳'
            }
        },
    },
}
</script>
<style lang="scss">
body,
html {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

#app {
    width: 100%;
    height: 100%;
}

.richText p {
    line-height: 26px;
}
</style>

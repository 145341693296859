import { post, get  } from "./axios.js";
let api = {

//查询轮播
findAdvertise: json => {
  return get("/client/advertise/findAdvertise", json);
},

//查询首页
findHomeData: json => {
  return get("/client/home/findHomeData", json);
},

//查询所有服务范围分类
findServiceAreaCategory: json => {
  return get("/client/server_category/findServiceAreaCategory", json);
},

//添加预约
addReserve: json => {
  return post("/client/reserve/addReserve", json);
},
//获取服务范围信息
findServiceArea: json => {
  return get("/client/service_area/findServiceArea", json);
},
//查询一级分类
findOneSolution: json => {
  return get("/client/solution/findOneSolution", json);
},
//查询二级分类
findTwoSolution: json => {
  return get("/client/solution/findTwoSolution", json);
},
//查询三级分类
findThreeSolution: json => {
  return get("/client/solution/findThreeSolution", json);
},
 //查询分类详情
findSolutionDetails: json => {
  return get("/client/solution/findSolutionDetails", json);
},
 //查询工程案例分类
findProjectCaseCategory: json => {
  return get("/client/case_category/findProjectCaseCategory", json);
},
//分页查询工程案例
findPageProjectCase: json => {
  return get("/client/project_case/findPageProjectCase", json);
}, 
//查询工程案例详情
findProjectCaseDetails: json => {
  return get("/client/project_case/findProjectCaseDetails", json);
},
//查询商品分类
findGoodsClassification: json => {
  return get("/client/goods/findGoodsClassification", json);
},
//查询商品列表
findPageGoodsList: json => {
  return get("/client/goods/findPageGoodsList", json);
},
//查询商品详情
findGoodsDetails: json => {
  return get("/client/goods/findGoodsDetails", json);
},
//分页获取新闻资讯信息
findPageNews: json => {
  return get("/client/news/findPageNews", json);
},
//查询新闻详情
findNewsDetails: json => {
  return get("/client/news/findNewsDetails", json);
},
//查询推荐新闻
findRecommendNews: json => {
  return get("/client/news/findRecommendNews", json);
},
//分页获取视频
findPageVideo: json => {
  return get("/client/video/findPageVideo", json);
},
//查询关于我们信息
findAboutUsInfo: json => {
  return get("/client/about/findAboutUsInfo", json);
},
//查询平台信息
findPlatformInfo: json => {
  return get("/client/platform/findPlatformInfo", json);
},
//查询导航菜单信息
findNavigationData: json => {
  return get("/client/platform/findNavigationData", json);
},
//查询商品分类信息
getGoodsClassificationInfo: json => {
  return get("/client/service_area/getGoodsClassificationInfo", json);
},
//查询果尔佳站群
findGejStationGroup: json => {
  return get("/client/gej-station/findGejStationGroup", json);
},
//获取信息
getRenovationWaterproofingInfo: json => {
    return get("/client/renovation_waterproofing/getInfo", json);
},
    

};


export default api;

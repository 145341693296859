import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/common.css"
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import * as echarts from 'echarts'
import api from "./http/api";
router.afterEach((to,from,next) => {
    window.scrollTo(0,0);
});
Vue.prototype.$echarts = echarts
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.api = api;

//前置守卫
router.beforeEach((to, from, next) => {
     setTimeout(() => {
         if (to.meta.content) {
             let head = document.getElementsByTagName('head');
             let meta = document.createElement('meta');
             document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
             document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
             meta.content = to.meta.content;
             head[0].appendChild(meta)
         }
         if (to.meta.title) {
           
            //  document.title = to.meta.title+'—果尔佳'
         }
         
        }, 500)
        next()
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
    const state = {
        typeId:null,
        serviceType:[],
        projectCase:[],
        solution:[],
        goodsData:[],
        journalism:[
            {
                id: 1,
                label: '公司新闻'
            },
            {
                id: 2,
                label: '行业资讯'
            },
            {
                id: 3,
                label: '视频中心'
            }
        ],
        aboutUsList:[
            {
                id: 1,
                label: '公司简介'
            },
            {
                id: 2,
                label: '发展历程'
            },
            {
                id: 3,
                label: '荣誉资质'
            },
            {
                id: 4,
                label: '企业文化'
            }
        ],
        isShow:false
    };
   
    //服务类型
    if(
        sessionStorage.getItem("serviceType") != null &&
        sessionStorage.getItem("serviceType") != undefined
    ){
        state.serviceType = JSON.parse(sessionStorage.getItem("serviceType"));
    }
    //解决方案
    if(
        sessionStorage.getItem("solution") != null &&
        sessionStorage.getItem("solution") != undefined
    ){
        state.solution = JSON.parse(sessionStorage.getItem("solution"));
    }
    //工程案例分类
    if(
        sessionStorage.getItem("projectCase") != null &&
        sessionStorage.getItem("projectCase") != undefined
    ){
        state.projectCase = JSON.parse(sessionStorage.getItem("projectCase"));
    }
    //商品分类
    if(
        sessionStorage.getItem("goodsData") != null &&
        sessionStorage.getItem("goodsData") != undefined
    ){
        state.goodsData = JSON.parse(sessionStorage.getItem("goodsData"));
    }
    const mutations ={
        //服务类型
        getServiceType(state,data){
            state.serviceType = data
            sessionStorage.setItem("serviceType",JSON.stringify(data)) 
        },

        //解决方案
        getSolution(state,data){
            state.solution = data
            sessionStorage.setItem("solution",JSON.stringify(data)) 
        },

        //工程案例
        getProjectCase(state,data){
            state.projectCase = data
            sessionStorage.setItem("projectCase",JSON.stringify(data)) 
        },
        //商品分类
        getGoodsData(state,data){
            state.goodsData = data
            sessionStorage.setItem("goodsData",JSON.stringify(data)) 
        },
        //分类类型id
        getTypeId(state,data){
            state.typeId = data
            sessionStorage.setItem("typeId",JSON.stringify(data)) 
        },
    };
    const getters = {};
    const modules = {};
  export default new Vuex.Store({
    state,
    mutations,
    getters,
    modules,
});